exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mushrooms-js": () => import("./../../../src/pages/mushrooms.js" /* webpackChunkName: "component---src-pages-mushrooms-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-templates-mushroom-js": () => import("./../../../src/templates/mushroom.js" /* webpackChunkName: "component---src-templates-mushroom-js" */),
  "component---src-templates-post-entry-js": () => import("./../../../src/templates/postEntry.js" /* webpackChunkName: "component---src-templates-post-entry-js" */)
}

